import moment from "moment/moment";
import React from "react";

export class TableCellFormater {
    static cellFormatter(cell: string, row: any, rowIndex: number, formatExtraData?: any): React.ReactNode {
        // If cell contains only http links, covert it to <a> element to be clickable
        if (/^https:[\S]+/.test(cell)) {
            return <a target="_blank" href={cell} rel="noreferrer">Click to view</a>
        }
        // If cell contains only date/time, convert it to local date/time
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z$/.test(cell)) {
            let localDate = new Date(cell)
            return moment(localDate).format('YYYY/MM/DD, HH:mm')
        }

        // Render color scheme based on hard-coded map
        return cell
    }
}
