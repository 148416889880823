export class StringFormatter {
    public static camelCaseToSpaceSeperatedString(str: string): string {
        let strWithSpace: string = str.replace(/([A-Z][a-z])|(CRISPR|GC)/g, " $1$2")
        return strWithSpace.charAt(0).toUpperCase() + strWithSpace.slice(1)
    }

    public static snakeCaseToSpaceSeperatedCapitalizedString(str: string): string {
        var strArray: string[] = str.split("_")
        var capitalizedStrArr: string[] = []
        for (let lowerCaseWord of strArray) {
            let toBeConvertedWord: string = lowerCaseWord.trim().toLowerCase()
            if (["nsl", "sl", "ss", "na", "ko", "fv", "bliss", "fu", "id", "cv", "ntc", "polr2d", "prmt5", "mtap"].includes(toBeConvertedWord)) {
                toBeConvertedWord = toBeConvertedWord.toUpperCase()
            } else {
                toBeConvertedWord = toBeConvertedWord.charAt(0).toUpperCase() + toBeConvertedWord.slice(1)
            }
            capitalizedStrArr.push(toBeConvertedWord)
        }

        return capitalizedStrArr.join(" ")
    }
}