import {Logger} from "aws-amplify";
import {ErrorMessage, Field, Form, Formik, FormikValues} from "formik";
import {FormikHelpers} from "formik/dist/types";
import React from "react";
import {Button, Card, Col, FormLabel, Row} from "react-bootstrap";
import {v4 as uuidv4} from 'uuid';
import * as yup from "yup";
import {EventType, FrontendModule} from "../CommonApi/Enum";
import {UsageTracking} from "../CommonApi/UsageTracking";
import {SearchBoxComparator} from "./Common/Enum";
import {FormikReactSelect} from "./Common/FormikReactSelect";
import {GenGenPlateAggregatedSearchResultTable} from "./GenGenPlate/Aggregated/GenGenPlateAggregatedSearchResultTable";
import {View} from "./GenGenPlate/Enum";
import {CellLineSearchResultTable} from "./GuidePlasmidIndel/CellLineSearchResultTable";
import {GuideSearchResultTable} from "./GuidePlasmidIndel/GuideSearchResultTable";
import {IndelSearchResultTable} from "./GuidePlasmidIndel/IndelSearchResultTable";
import {PlasmidSearchResultTable} from "./GuidePlasmidIndel/PlasmidSearchResultTable";
import {WesternBlotSearchResultTable} from "./GuidePlasmidIndel/WesternBlotSearchResultTable";
import {HGNCSearchResultTable} from "./Hgnc/HGNCSearchResultTable";
import {DataHubSearchResultTable} from "./DataHub/DataHubSearchResultTable"

const logger = new Logger("DatabasesSearch.tsx")
const TABLE_LIST = [
    {value: "hgnc", label: "HUGO Gene Nomenclature"},
    {value: "cell_line", label: "Cell line"},
    {value: "plasmid", label: "Plasmid"},
    {value: "indel_performance", label: "Indel performance"},
    {value: "western_blot", label: "Western blot"},
    {value: "guide", label: "Guide"},
    {value: "gen_gen_plate", label: "Gen-gen plate"},
    {value: "s3_gen_gen_pair_level", label: "Gen-gen pair summary"},
    {value: "s3_gen_gen_cell_line_level", label: "Gen-gen cell line summary"},
    {value: "s3_gen_gen_plate_level", label: "Gen-gen plate summary"},
    {value: "s3_gen_gen_combo_level", label: "Gen-gen combo summary"},
]


function getTableFromTableList(tableName: string): {value: string, label:string} {
    for (var table of TABLE_LIST) {
        if (tableName === table.value) {
            return table
        }
    }
    return {value: "", label: ""}
}

interface SearchState {
    initialValues: any
    schema: any
    search: {
        refreshToken: string
        display: boolean
        keywords: string
        table: string[]
    }
}

export class Search extends React.Component<any, SearchState> {
    constructor(props: any) {
        super(props)
        this.state = {
            initialValues: {
                keywords: "",
                table: [],
            },
            schema: yup.object({
                keywords: yup.string().required(),
                table: yup.array().min(1),
            }),
            search: {
                display: false,
                refreshToken: uuidv4(),
                keywords: "",
                table: [],
            },
        }
    }

    searchDatabase(values: any, formikHelpers: FormikHelpers<FormikValues>): void | Promise<any> {
        logger.debug("searchDatabase is called")
        logger.info(values)
        UsageTracking.recordEvent({
            module: FrontendModule.DataCatalog,
            event: EventType.Search,
            table: values["table"].toString(),
            database: "any",
            keywords: values["keywords"], // Comma seperated keywords is splited to seperate Cloudwatch log at backend.
        })
        this.setState({
            search: {
                refreshToken: uuidv4(), // Send refresh token everytime search button is pressed
                display: true,
                keywords: values["keywords"],
                table: values["table"],
            }
        }, () => {
            formikHelpers.setSubmitting(false)
        })
    }

    render() {
        return (
            <>
                <Card className={"bg-light"}>
                    <Card.Body>
                        <Formik
                            initialValues={this.state.initialValues}
                            validationSchema={this.state.schema}
                            onSubmit={this.searchDatabase.bind(this)}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  setFieldValue,
                                  isSubmitting}) => (
                                <Form>
                                    <Row className={"mt-2"}>
                                        <Col md={1} lg={1}/>
                                        <Col md={3} lg={3}>
                                            <FormLabel>Search multiple tables</FormLabel>
                                            <FormikReactSelect
                                                   name={"table"}
                                                   id={"multi-select-table"}
                                                   isMulti={true}
                                                   formikSetFieldValue={setFieldValue}
                                                   value={values["table"]}
                                                   // className={"form-control form-control-lg"}
                                                   // disabled={isSubmitting}
                                                   options={TABLE_LIST}
                                            />
                                            <ErrorMessage
                                                name={"table"}
                                                component={"div"}
                                                className={"field-error text-danger"}
                                            />
                                        </Col>
                                        <Col md={6} lg={6}>
                                            <FormLabel>By keywords (search <span className={"text-primary"} style={{fontWeight: "bolder"}}>*</span> to get everything):</FormLabel>
                                            <Field type="text" name={"keywords"}
                                                   className={"form-control form-control-lg"}
                                                   disabled={isSubmitting} placeholder={"PL2534, FANCM, GGTAAAT"}/>
                                            <ErrorMessage
                                                name={"keywords"}
                                                component={"div"}
                                                className={"field-error text-danger"}
                                            />
                                        </Col>
                                        <Col md={1} lg={1}>
                                            <Button
                                                variant={"primary"}
                                                type={"submit"} disabled={isSubmitting}
                                                size={"lg"}
                                                // Ensure button is not dropping down when multi-select expands
                                                style={{position: "inherit", bottom: "1rem", marginTop: "2rem"}}>
                                                Search
                                             </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Card.Body>
                </Card>
                {this.state.search.display && <div>
                    <Row className={"mt-3"}>
                        {
                            this.state.search.keywords.trim() === "*" ?
                                <h5>Retrieve <span className={"text-primary"}>everything</span> in database:</h5> :
                                <h5>Search results for "{this.state.search.keywords.trim().split(",").join("\" OR \"")}":</h5>
                        }
                    </Row>
                    {this.state.search.table.includes("gen_gen_plate") && <GenGenPlateAggregatedSearchResultTable
                        refreshToken={this.state.search.refreshToken}
                        keywords={this.state.search.keywords}
                        comparator={SearchBoxComparator.Like}
                        view={View.Read} />}
                    {this.state.search.table.includes("cell_line") && <CellLineSearchResultTable
                        refreshToken={this.state.search.refreshToken}
                        keywords={this.state.search.keywords}
                        comparator={SearchBoxComparator.Like} />}
                    {this.state.search.table.includes("plasmid") && <PlasmidSearchResultTable
                        refreshToken={this.state.search.refreshToken}
                        keywords={this.state.search.keywords}
                        comparator={SearchBoxComparator.Like}
                        view={View.Read} />}
                    {this.state.search.table.includes("indel_performance") && <IndelSearchResultTable
                        refreshToken={this.state.search.refreshToken}
                        keywords={this.state.search.keywords}
                        comparator={SearchBoxComparator.Like}
                        view={View.Read} />}
                    {this.state.search.table.includes("western_blot") && <WesternBlotSearchResultTable
                        refreshToken={this.state.search.refreshToken}
                        keywords={this.state.search.keywords}
                        comparator={SearchBoxComparator.Like}
                        view={View.Read} />}
                    {this.state.search.table.includes("guide") && <GuideSearchResultTable
                        refreshToken={this.state.search.refreshToken}
                        keywords={this.state.search.keywords}
                        comparator={SearchBoxComparator.Like} />}
                    {this.state.search.table.includes("hgnc") && <HGNCSearchResultTable
                        refreshToken={this.state.search.refreshToken}
                        keywords={this.state.search.keywords}
                        comparator={SearchBoxComparator.Like} />}
                    {this.state.search.table.includes("s3_gen_gen_pair_level") && <DataHubSearchResultTable
                        refreshToken={this.state.search.refreshToken}
                        keywords={this.state.search.keywords}
                        tableName={getTableFromTableList("s3_gen_gen_pair_level").value}
                        tableTitle={getTableFromTableList("s3_gen_gen_pair_level").label}
                    />}
                    {this.state.search.table.includes("s3_gen_gen_cell_line_level") && <DataHubSearchResultTable
                        refreshToken={this.state.search.refreshToken}
                        keywords={this.state.search.keywords}
                        tableName={getTableFromTableList("s3_gen_gen_cell_line_level").value}
                        tableTitle={getTableFromTableList("s3_gen_gen_cell_line_level").label}
                    />}
                    {this.state.search.table.includes("s3_gen_gen_plate_level") && <DataHubSearchResultTable
                        refreshToken={this.state.search.refreshToken}
                        keywords={this.state.search.keywords}
                        tableName={getTableFromTableList("s3_gen_gen_plate_level").value}
                        tableTitle={getTableFromTableList("s3_gen_gen_plate_level").label}
                    />}
                    {this.state.search.table.includes("s3_gen_gen_combo_level") && <DataHubSearchResultTable
                        refreshToken={this.state.search.refreshToken}
                        keywords={this.state.search.keywords}
                        tableName={getTableFromTableList("s3_gen_gen_combo_level").value}
                        tableTitle={getTableFromTableList("s3_gen_gen_combo_level").label}
                    />}
                </div>}
            </>
        )
    }
}