import {shortenEnv} from "../../../Const";

export const dataHubApiConsts = {
    API_NAME: `${shortenEnv}-data-hub-data-api`,
    API_ENDPOINT: `https://${shortenEnv}-data-hub-data-api.enginebio.io`,
    DATABASE_NAME: `${shortenEnv}-data-hub`,
    API_PATH: {
        TABLE_DEFINITION: "/table/definition",
        COLUMN_METADATA: "/table/column-metadata",
        COLUM_ORDER: "/table/column-order",
        TABLE_DATA: "/table/data",
    },
}