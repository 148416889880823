import {API, Logger} from "aws-amplify";
import React from "react";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import BootstrapTable, {ColumnDescription} from "react-bootstrap-table-next";

// @ts-ignore
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor'
import {v4 as uuidv4} from "uuid";
import {USER_EMAIL} from "../../../Auth/LoginUser";
import {DataAudit} from "../../../CommonApi/DataAudit";
import {EventType, FrontendModule} from "../../../CommonApi/Enum";
import {UsageTracking} from "../../../CommonApi/UsageTracking";
import {ApiCallStatus} from "../../Common/Enum";
import {gppApiConsts as gppApiConst} from "../../GuidePlasmidIndel/GppApiConsts";
import {Plasmid} from "../../GuidePlasmidIndel/PlasmidForm";
import {DeliveryMethod, View} from "../Enum";
import {genGenPlateApiConst} from "../GenGenPlateApiConst";
import {PlateRow} from "../PlateRow";
import {GenGenPlateMap} from "./GenGenPlateMapForm";
import {wellClassFormatter} from "./PlateMapCellFormatter";

const logger = new Logger("GenGenPlateMapPlasmid")

interface GenGenPlateMapPlasmidState {
    columns: ColumnDescription[]
    updateNote: string
    owner: string
    user: string
    plateMap: string
    plateMapSubmissionStatus: ApiCallStatus
    plateMapSubmissionMessage: string
    plateMapRetrievalStatus: ApiCallStatus
    plateMapRetrievalMessage: string
    possiblePlasmidList: Plasmid[]
    possiblePlasmidRetrievalStatus: ApiCallStatus
    possiblePlasmidRetrievalMessage: string
}

interface GenGenPlateMapPlasmidProps {
    genGenPlateMap: GenGenPlateMap
    geneAs: string
    geneBs: string
    owner: string
    view: View
    refreshToken: string
}

const DEFAULT_COLUMN_LIST: any[] = [
    {dataField: "0", text: ""},
    {
        dataField: "A",
        text: "1",
        editor: {type: Type.SELECT, options: []},
        classes: wellClassFormatter
    },
    {
        dataField: "B",
        text: "2",
        editor: {type: Type.SELECT, options: []},
        classes: wellClassFormatter
    },
    {
        dataField: "C",
        text: "3",
        editor: {type: Type.SELECT, options: []},
        classes: wellClassFormatter
    },
    {
        dataField: "D",
        text: "4",
        editor: {type: Type.SELECT, options: []},
        classes: wellClassFormatter
    },
    {
        dataField: "E",
        text: "5",
        editor: {type: Type.SELECT, options: []},
        classes: wellClassFormatter
    },
    {
        dataField: "F",
        text: "6",
        editor: {type: Type.SELECT, options: []},
        classes: wellClassFormatter
    },
    {
        dataField: "G",
        text: "7",
        editor: {type: Type.SELECT, options: []},
        classes: wellClassFormatter
    },
    {
        dataField: "H",
        text: "8",
        editor: {type: Type.SELECT, options: []},
        classes: wellClassFormatter
    },
    {
        dataField: "I",
        text: "9",
        editor: {type: Type.SELECT, options: []},
        classes: wellClassFormatter
    },
    {
        dataField: "J",
        text: "10",
        editor: {type: Type.SELECT, options: []},
        classes: wellClassFormatter
    },
    {
        dataField: "K",
        text: "11",
        editor: {type: Type.SELECT, options: []},
        classes: wellClassFormatter
    },
    {
        dataField: "L",
        text: "12",
        editor: {type: Type.SELECT, options: []},
        classes: wellClassFormatter
    },
]

const READ_ONLY_COLUMN_LIST: ColumnDescription[] = [
    {dataField: "0", text: "", classes: wellClassFormatter},
    {dataField: "A", text: "1", classes: wellClassFormatter},
    {dataField: "B", text: "2", classes: wellClassFormatter},
    {dataField: "C", text: "3", classes: wellClassFormatter},
    {dataField: "D", text: "4", classes: wellClassFormatter},
    {dataField: "E", text: "5", classes: wellClassFormatter},
    {dataField: "F", text: "6", classes: wellClassFormatter},
    {dataField: "G", text: "7", classes: wellClassFormatter},
    {dataField: "H", text: "8", classes: wellClassFormatter},
    {dataField: "I", text: "9", classes: wellClassFormatter},
    {dataField: "J", text: "10", classes: wellClassFormatter},
    {dataField: "K", text: "11", classes: wellClassFormatter},
    {dataField: "L", text: "12", classes: wellClassFormatter},
]

export const DEFAULT_PLATE_MAP: string =
    `empty empty empty empty empty empty empty empty empty empty empty empty\n` +
    `PL945 PL851_for_count PL851 empty empty empty empty empty empty empty empty media_only\n` +
    `PL945 PL851_for_count PL851 as_above as_above as_above as_above as_above as_above as_above as_above media_only\n` +
    `PL945 PL851_for_count PL851 as_above as_above as_above as_above as_above as_above as_above as_above media_only\n` +
    `nv_puro PL681 PL675 empty empty empty empty empty empty empty empty nv\n` +
    `nv_puro PL681 PL675 as_above as_above as_above as_above as_above as_above as_above as_above nv\n` +
    `nv_puro PL681 PL675 as_above as_above as_above as_above as_above as_above as_above as_above nv\n` +
    `empty empty empty empty empty empty empty empty empty empty empty empty`

export class GenGenPlateMapPlasmid extends React.Component<GenGenPlateMapPlasmidProps, GenGenPlateMapPlasmidState> {
    static defaultProps = {
        genGenPlateMap: {
            plateId: "",
            deliveryMethod: DeliveryMethod.Lentivirus,
            geneAs: "", // Important for getting possible plasmid lists
            geneBs: "",
            plateMap: ""
        }
    }

    constructor(props: any) {
        super(props);
        this.state = {
            columns: DEFAULT_COLUMN_LIST,
            plateMap: "",
            plateMapSubmissionStatus: ApiCallStatus.NoData,
            plateMapSubmissionMessage: "",
            plateMapRetrievalStatus: ApiCallStatus.NoData,
            plateMapRetrievalMessage: "",
            possiblePlasmidList: [],
            possiblePlasmidRetrievalStatus: ApiCallStatus.NoData,
            possiblePlasmidRetrievalMessage: "",
            user: USER_EMAIL,
            owner: props.owner,
            updateNote: ""
        }
    }

    componentDidMount() {
        this.updateColumnsWithPossiblePlasmidLists()
    }

    componentDidUpdate(prevProps: Readonly<GenGenPlateMapPlasmidProps>, prevState: Readonly<GenGenPlateMapPlasmidState>, snapshot?: any) {
        // Update possible plasmid list when props change
        if (prevProps.refreshToken !== this.props.refreshToken ||
            prevProps.genGenPlateMap.plateId !== this.props.genGenPlateMap.plateId ||
            this.props.geneAs !== prevProps.geneAs ||
            this.props.geneBs !== prevProps.geneBs
        ) {
            this.updateColumnsWithPossiblePlasmidLists()
        }
    }

    updateColumnsWithPossiblePlasmidLists() {
        if (this.props.view === View.Read) {
            this.setState({
                plateMap: this.props.genGenPlateMap.plateMap,
                columns: READ_ONLY_COLUMN_LIST // It is weird that we have to put it here, should it be default somewhere?
            })
            return;
        }

        if (this.props.geneAs === "" || this.props.geneBs === "") {
            return
        }

        this.setState({
            possiblePlasmidRetrievalStatus: ApiCallStatus.Loading,
            possiblePlasmidList: [],
            possiblePlasmidRetrievalMessage: "",
        }, () => {
            API.get(
                gppApiConst.API_NAME,
                gppApiConst.plasmid.path.GENE_A_GENE_B,
                {
                    headers: {},
                    response: true,
                    queryStringParameters: {
                        "genea": this.props.geneAs,
                        "geneb": this.props.geneBs,
                    }},
            ).then(response => {
                logger.debug("Response from API", response)
                let editable = this.props.view !== View.Read
                let validPlasmidsAsOptions = this.convertPlasmidListToOptions(response.data.data)

                // Handling case where this.props.genGenPlateMap.plateMap contains no-longer-valid plasmid
                let plasmidIds: string[] = response.data.data.map((plasmid: Plasmid) => plasmid.id)
                let plateMap = PlateRow.convertStrToPlateMapRowList(this.props.genGenPlateMap.plateMap)
                for (var row of plateMap) {
                    Object.keys(row).forEach((key: string) => {
                        // @ts-ignore
                        console.log(`row[${key}] = ${row[key]}`)
                        // @ts-ignore
                        if (row[key].startsWith("PL") && row[key] !== "PL945" &&
                            // @ts-ignore
                            row[key] !== "PL851_for_count" && !plasmidIds.includes(row[key])) {
                            // @ts-ignore
                            console.log(`Change ${row[key]} to need_update`)
                            // @ts-ignore
                            row[key] = "need_update"
                        }
                    })
                }
                console.log("platemap = ", plateMap)

                this.setState({
                    plateMap: this.props.view === View.Import ? DEFAULT_PLATE_MAP : PlateRow.convertPlateMapRowListToStr(plateMap),
                    possiblePlasmidRetrievalStatus: ApiCallStatus.Completed,
                    possiblePlasmidRetrievalMessage: "",
                    possiblePlasmidList: response.data,
                    columns: [
                        {dataField: "0", text: ""},
                        {
                            dataField: "A",
                            text: "1",
                            editor: {type: Type.SELECT, options: validPlasmidsAsOptions},
                            editable: editable,
                            classes: wellClassFormatter,
                        },
                        {
                            dataField: "B",
                            text: "2",
                            editor: {type: Type.SELECT, options: validPlasmidsAsOptions},
                            editable: editable,
                            classes: wellClassFormatter
                        },
                        {
                            dataField: "C",
                            text: "3",
                            editor: {type: Type.SELECT, options: validPlasmidsAsOptions},
                            editable: editable,
                            classes: wellClassFormatter
                        },
                        {
                            dataField: "D",
                            text: "4",
                            editor: {type: Type.SELECT, options: validPlasmidsAsOptions},
                            editable: editable,
                            classes: wellClassFormatter
                        },
                        {
                            dataField: "E",
                            text: "5",
                            editor: {type: Type.SELECT, options: validPlasmidsAsOptions},
                            editable: editable,
                            classes: wellClassFormatter
                        },
                        {
                            dataField: "F",
                            text: "6",
                            editor: {type: Type.SELECT, options: validPlasmidsAsOptions},
                            editable: editable,
                            classes: wellClassFormatter
                        },
                        {
                            dataField: "G",
                            text: "7",
                            editor: {type: Type.SELECT, options: validPlasmidsAsOptions},
                            editable: editable,
                            classes: wellClassFormatter
                        },
                        {
                            dataField: "H",
                            text: "8",
                            editor: {type: Type.SELECT, options: validPlasmidsAsOptions},
                            editable: editable,
                            classes: wellClassFormatter
                        },
                        {
                            dataField: "I",
                            text: "9",
                            editor: {type: Type.SELECT, options: validPlasmidsAsOptions},
                            editable: editable,
                            classes: wellClassFormatter
                        },
                        {
                            dataField: "J",
                            text: "10",
                            editor: {type: Type.SELECT, options: validPlasmidsAsOptions},
                            editable: editable,
                            classes: wellClassFormatter
                        },
                        {
                            dataField: "K",
                            text: "11",
                            // Generate allPossiblePlasmids is better than https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/basic-celledit.html#editorgetoptions
                            // Because we only need to make one API call
                            editor: {type: Type.SELECT, options: validPlasmidsAsOptions},
                            editable: editable,
                            classes: wellClassFormatter
                        },
                        {
                            dataField: "L",
                            text: "12",
                            editor: {type: Type.SELECT, options: validPlasmidsAsOptions},
                            editable: editable,
                            classes: wellClassFormatter
                        },
                    ],
                })
            }).catch(err => {
                logger.error(err)
                this.setState({
                    possiblePlasmidList: [],
                    possiblePlasmidRetrievalStatus: ApiCallStatus.Error,
                    possiblePlasmidRetrievalMessage: err.response.data.message,
                    columns: DEFAULT_COLUMN_LIST
                })
            })
        })
    }

    convertPlasmidListToOptions(plasmidList: Plasmid[]): {value: string, label: string}[] {
        let result: {value: string, label: string}[] = [
            {value: "as_above", label: "as_above"},
        ]
        for (const plasmid of plasmidList) {
            result.push({value: plasmid.id, label: `${plasmid.id} - ${plasmid.name}`})
        }
        result.push(...[
            {value: "nv", label: "nv"},
            {value: "nv_puro", label: "nv_puro"},
            {value: "empty", label: "empty"},
            {value: "media_only", label: "media_only"},
            {value: "need_update", label: "need_update"},
            {value: "PL945", label: "PL945"},
            {value: "PL851_for_count", label: "PL851_for_count"},
        ])
        return result
    }

    submitPlateMap() {
        // Form validation
        if (this.state.plateMap.includes("need_update")) {
            this.setState({
                plateMapSubmissionStatus: ApiCallStatus.Error,
                plateMapSubmissionMessage: "Change \"need_update\" to correct plasmid"
            })
            return
        }

        if (this.props.view === View.Update) {
            if (this.state.updateNote === "") {
                this.setState({
                    plateMapSubmissionStatus: ApiCallStatus.Error,
                    plateMapSubmissionMessage: "Update note cannot be empty"
                })
                return
            }
        }

        UsageTracking.recordEvent({
            uuid: uuidv4(),
            module: FrontendModule.DataCatalog,
            event: this.props.view === View.Update ? EventType.Update : EventType.Import,
            table: genGenPlateApiConst.tableName.MAP,
            database: genGenPlateApiConst.DATABASE_NAME,
            keywords: "", // Comma seperated
            timestamp: new Date()
        })

        this.setState({
            plateMapSubmissionStatus: ApiCallStatus.Loading,
            plateMapSubmissionMessage: "",
        }, () => {
            if (this.props.view === View.Import) {
                API.put(
                    genGenPlateApiConst.API_NAME,
                    genGenPlateApiConst.path.MAP,
                    {
                        body: {
                            plateId: this.props.genGenPlateMap.plateId,
                            plateMap: this.state.plateMap,
                        },
                    }).then(response => {
                        DataAudit.recordEvent({
                            database: genGenPlateApiConst.DATABASE_NAME,
                            table: genGenPlateApiConst.tableName.MAP,
                            rowId: this.props.genGenPlateMap.plateId,
                            updateNote: "",
                            dataObject: {
                                plateId: this.props.genGenPlateMap.plateId,
                                plateMap: this.state.plateMap,
                                user: this.state.user,
                            },
                        })

                        // Set success message
                        this.setState({
                            plateMapSubmissionStatus: ApiCallStatus.Completed,
                            plateMapSubmissionMessage: "",
                        })
                    }).catch(err => {
                        logger.error(err)
                        this.setState({
                            plateMapSubmissionStatus: ApiCallStatus.Error,
                            plateMapSubmissionMessage: err.response.data.message,
                        })
                    })
            } else if (this.props.view === View.Update) {
                API.patch(
                    genGenPlateApiConst.API_NAME,
                    genGenPlateApiConst.path.MAP,
                    {
                        body: {
                            plateId: this.props.genGenPlateMap.plateId,
                            plateMap: this.state.plateMap,
                            additionalNote: this.state.updateNote,
                        },
                    }).then(response => {
                        DataAudit.recordEvent({
                            database: genGenPlateApiConst.DATABASE_NAME,
                            table: genGenPlateApiConst.tableName.MAP,
                            rowId: this.props.genGenPlateMap.plateId,
                            updateNote: this.state.updateNote,
                            dataObject: {
                                plateId: this.props.genGenPlateMap.plateId,
                                plateMap: this.state.plateMap,
                                user: this.state.user,
                            },
                        })

                        // Set success message
                        this.setState({
                            plateMapSubmissionStatus: ApiCallStatus.Completed,
                            plateMapSubmissionMessage: "",
                        })
                    }).catch(err => {
                        logger.error(err)
                        this.setState({
                            plateMapSubmissionStatus: ApiCallStatus.Error,
                            plateMapSubmissionMessage: err.response.data.message,
                        })
                    })
            }
        })
    }

    onAfterSaveWell(oldCellValue: any, newValue: string, row: PlateRow, colIndex: ColumnDescription): void {
        let plateMapAsPlateMapRowList: PlateRow[] = PlateRow.convertStrToPlateMapRowList(this.state.plateMap)
        plateMapAsPlateMapRowList[row["0"].charCodeAt(0) - 'A'.charCodeAt(0)] = row
        this.setState({
           plateMap: PlateRow.convertPlateMapRowListToStr(plateMapAsPlateMapRowList)
        })
    }

    handleUpdateNoteChange(e: any) {
        this.setState({
            updateNote: e.target.value
        })
    }

    render() {
        // Except for view Read, all other view should trigger updateColumnsWithPossiblePlasmidLists() which would change ApiCallStatus.NoData to something else
        if (this.state.possiblePlasmidRetrievalStatus === ApiCallStatus.NoData && this.props.view !== View.Read) {
            return <></>
        }

        if (this.state.possiblePlasmidRetrievalStatus === ApiCallStatus.Loading) {
            return <p className={"text-primary"}>Finding all plasmids of {this.props.geneAs} and {this.props.geneBs} ...</p>
        }
        if (this.state.possiblePlasmidRetrievalStatus === ApiCallStatus.Error) {
            return <Alert className={"mt-3"} variant={"danger"}>Error finding plasmid matching geneA = {this.props.geneAs} and/or geneB = {this.props.geneBs}</Alert>
        }

        if (this.state.plateMapRetrievalStatus === ApiCallStatus.Loading) {
            return <p className={"text-primary"}>Retrieving plate map ...</p>
        }
        if (this.state.plateMapRetrievalStatus === ApiCallStatus.Error) {
            return <Alert className={"mt-3"} variant={"danger"}>{this.state.plateMapRetrievalMessage}</Alert>
        }

        if ((this.props.view === View.Update || this.props.view === View.Read) && !this.state.plateMap) {
            return <Alert variant={"info"}>No plate map uploaded</Alert>
        }

        return <>
            <p>Plate Map</p>
            <BootstrapTable
                hover
                bordered={true}
                wrapperClasses={"table-responsive"}
                keyField={"0"}
                columns={this.state.columns}
                data={PlateRow.convertStrToPlateMapRowList(this.state.plateMap)}
                rowClasses = {() => ""}
                cellEdit={
                    this.props.view === View.Read ? false : cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: this.onAfterSaveWell.bind(this)
                    })
                }
                noDataIndication={<h6 className={"text-primary"}>No data found!</h6>}
            />
            {
                this.props.view === View.Update &&
                <Row>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>User</Form.Label>
                            <Form.Control type={"text"} disabled value={this.state.user}/>
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>Update note</Form.Label>
                            <Form.Control type={"text"} value={this.state.updateNote} onChange={this.handleUpdateNoteChange.bind(this)}/>
                        </Form.Group>
                    </Col>
                </Row>
            }
            {
                this.state.plateMapSubmissionStatus === ApiCallStatus.Error &&
                <Row className={"mt-2"}>
                    <Col md={12}>
                        <Alert className={"mt-3"} variant={"danger"}>
                            {this.state.plateMapSubmissionMessage}
                        </Alert>
                    </Col>
                </Row>
            }
            {
                this.state.plateMapSubmissionStatus === ApiCallStatus.Completed &&
                <Row className={"mt-2"}>
                    <Col md={12}>
                        <Alert variant={"success"}>
                            Plate ID {this.props.genGenPlateMap.plateId} submitted successfully!
                        </Alert>
                    </Col>
                </Row>
            }
            {
                this.props.view !== View.Read &&
                <Button className={"mt-2 float-end"} variant={"primary"}
                        onClick={this.submitPlateMap.bind(this)}
                        disabled={this.state.plateMapSubmissionStatus === ApiCallStatus.Loading}>
                    {this.state.plateMapSubmissionStatus === ApiCallStatus.Loading ? "Submitting ..." : "Submit"}
                </Button>
            }
        </>;
    }
}