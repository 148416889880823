import {API, Logger} from "aws-amplify";
import React from "react";
import {ApiCallStatus, SearchBoxComparator} from "../Common/Enum";
import {SearchResultTableViewOnly} from "../Common/SearchResultTableViewOnly";
import {gppApiConsts} from "../GuidePlasmidIndel/GppApiConsts";
import {ColumnMetadata} from "../Common/ColumnMetadata";

const logger = new Logger("HGNCSearchResultTable.tsx")

interface HGNCSearchResultTableState {
    data: {
        rows: HGNCGeneEntity[]
    }
    status: ApiCallStatus
    errorMessage: string
}

interface HGNCGeneEntity {
    aliasSymbol: string[],
    ensemblID: string,
    entrezID: string,
    hgncID: string,
    location: string,
    locusGroup: string,
    locusType: string,
    name: string,
    previousSymbol: string[],
    status: string,
    symbol: string,
    uniprodID: string[],
}

interface HGNCSearchResultTableProps {
    refreshToken: string
    keywords: string
    comparator: SearchBoxComparator
}

const HGNC_COLUMN_DEFINITION: ColumnMetadata[] = [
    {
        name: "symbol",
        definition: "The official gene symbol that has been approved by the HGNC and is publicly available. Symbols are approved based on specific HGNC nomenclature guidelines.",
        hidden: false
    },
    {
        name: "previousSymbol",
        definition: "Symbols previously approved by the HGNC for this gene.",
        hidden: false
    },
    {
        name: "aliasSymbol",
        definition: "Other symbols used to refer to this gene.",
        hidden: false
    },
    {
        name: "name",
        definition: "The official gene name that has been approved by the HGNC and is publicly available. Names are approved based on specific HGNC nomenclature guidelines.",
        hidden: false
    },
    {
        name: "hgncID",
        definition: "A unique ID provided by the HGNC for each gene with an approved symbol. IDs are of the format HGNC:n, where n is a unique number",
        hidden: false
    },
    {
        name: "locusType",
        definition: "Specifies the type of locus described by the given entry. For more detail visit https://www.genenames.org/help/biomart/",
        hidden: false
    },
    {
        name: "status",
        definition: "Indicates whether the gene is classified as:\n" +
            "\n" +
            "    Approved - these genes have HGNC-approved gene symbols\n" +
            "    Entry withdrawn - these previously approved genes are no longer thought to exist\n" +
            "    Symbol withdrawn - a previously approved record that has since been merged into a another record\n",
        hidden: false
    },
]
const HGNC_TABLE_DEF = "HGNC data, update every day."
const HGNC_DATA_ADMIN = "niklas.kasenburg@enginebio.com"

export class HGNCSearchResultTable extends React.Component<HGNCSearchResultTableProps, HGNCSearchResultTableState> {
    constructor(props: HGNCSearchResultTableProps) {
        super(props);
        this.state = {
            data: {
                rows: [],
            },
            status: ApiCallStatus.NoData,
            errorMessage: "",
        }
    }

    componentDidMount() {
        if (this.props.keywords !== "") {
            this.filterByColumns()
        }
    }

    componentDidUpdate(prevProps: Readonly<HGNCSearchResultTableProps>) {
        if (prevProps.refreshToken !== this.props.refreshToken) {
            this.filterByColumns()
        }
    }

    filterByColumns() {
        logger.debug("Start HGNC API call")
        this.setState({status: ApiCallStatus.Loading, errorMessage: ""})
        API.get(
            // TODO in future - move HGC api from GPP to common-api, or make both private and public endpoint for it.
            gppApiConsts.API_NAME,
            gppApiConsts.hgnc.path.GET_GENE_SYMBOL,
            {
                queryStringParameters: {
                    keywords: this.props.keywords,
                    onlyApprovedSymbols: "false",
                    returnBestMatchOnly: "false",
                },
            }).then(response => {
                logger.debug("Response from API")
                logger.debug(response)
                let rows: HGNCGeneEntity[] = []
                for (let row of response["data"]) {
                    rows.push(...row["approvedSymbols"])
                }
                logger.debug(rows)
                this.setState({
                    data: {
                        rows: rows
                    },
                    status: ApiCallStatus.Completed,
                    errorMessage: "",
                })
            }).catch(err => {
                logger.error(err)
                this.setState({
                    status: ApiCallStatus.Error,
                    errorMessage: err+""
                })
            }).finally(() => {

            })
    }

    render() {
        return <SearchResultTableViewOnly
            refreshToken={this.props.refreshToken}
            title={"HGNC - HUGO Gene Nomenclature Committee symbols"}
            tableName={"hgnc"}
            legends={<></>}
            rowClasses={() => ""}
            dataAdmin={HGNC_DATA_ADMIN}
            keyField={"hgncID"}
            columnsFormats={{
                "previousSymbol": (cell: string[]) => {
                    return <>{cell.map((symbol: string) => <>{symbol + ", "}</>)}</>
                },
                "aliasSymbol": (cell: string[]) => {
                    return <>{cell.map((alias: string) => <>{alias + ", "}</>)}</>
                }
            }}
            tableDescription={HGNC_TABLE_DEF}
            tableDefinitionStatus={ApiCallStatus.Completed}
            tableDefinitionErrorMessage={""}

            columnMetadata={HGNC_COLUMN_DEFINITION}
            columnMetadataStatus={ApiCallStatus.Completed}
            columnMetadataErrorMessage={""}

            tableData={this.state.data.rows}
            tableDataStatus={this.state.status}
            tableDataErrorMessage={this.state.errorMessage}
        />;
    }
}