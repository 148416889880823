import {Logger} from "aws-amplify";
import React from "react";
import {Alert, Badge, Card, Col, OverlayTrigger, Popover, Row} from "react-bootstrap";
import BootstrapTable, {ColumnDescription, ColumnFormatter} from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {ApiCallStatus} from "./Enum";
import ToolkitProvider, {Search, CSVExport} from "react-bootstrap-table2-toolkit";
import {ReactBootrapTableSorting} from "../../CommonFunc/ReactBootrapTableSorting";
import {TableCellFormater} from "../../CommonFunc/TableCellFormater";
import * as yup from "yup";
import {v4 as uuidv4} from "uuid";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import {ColumnMetadata} from "./ColumnMetadata";
import {StringFormatter} from "../../CommonFunc/StringFormatter";
import "../../CommonCss/TableToggle.css"
import "../../CommonCss/ExtraColor.css"
import {CloudDownload} from "react-bootstrap-icons";
import {TableColumnFormatter} from "../../CommonFunc/TableColumnFormatter";
import {ROW_LIMIT} from "./RowLimit";

const { ExportCSVButton } = CSVExport;

const logger = new Logger("SearchResultTableViewOnly.tsx")

const KEY_FIELD = "uuid_enginebio_io"

interface SearchResultTableViewOnlyProps {
    title: string
    smallTitle: string
    legends: any
    rowClasses: ((row: any, rowIndex: number) => string)
    columnsFormats: Record<string, ColumnFormatter<any, any, any> | undefined>
    refreshToken: string
    tableName: string

    dataAdmin: string
    tableDefinitionStatus: ApiCallStatus
    tableDefinitionErrorMessage: string
    tableDescription: string
    keyField: string

    columnMetadata: ColumnMetadata[]
    columnMetadataStatus: ApiCallStatus
    columnMetadataErrorMessage: string

    tableData: any[]
    tableDataStatus: ApiCallStatus
    tableDataErrorMessage: string

    displayPagination: boolean

    enableColumnFilter: boolean
    filterTableDataForOneColumn: (keywordsCommaSeperated: string, columnName: string, callbackFunc: () => void) => void

    enableSorting: boolean
}

interface SearchResultTableViewOnlyState {
    formikInitialState: {
        keywordsCommaSeperated: string
        columnName: string
    }
    keywordsCommaSeperated: string
    columnName: string
    columnDescriptions: ColumnDescription[]
    overallApiCallStatus: ApiCallStatus
}

export class SearchResultTableViewOnly extends React.Component<SearchResultTableViewOnlyProps, SearchResultTableViewOnlyState> {
    static defaultProps = {
        dataAdmin: "",
        enableSorting: false,
        displayPagination: true,
        enableColumnFilter: false,
        filterTableDataForOneColumn: () => {},
        smallTitle: "",
        keyField: "",
    }

    constructor(props: SearchResultTableViewOnlyProps) {
        super(props);
        this.state = {
            formikInitialState: {
                keywordsCommaSeperated: "",
                columnName: "",
            },
            keywordsCommaSeperated: "",
            columnName: "",
            columnDescriptions: [],
            overallApiCallStatus: ApiCallStatus.NoData,
        }
    }

    setApiOverallStatus() {
        // If one return error, overall is error
        if (this.props.columnMetadataStatus === ApiCallStatus.Error ||
            this.props.tableDefinitionStatus === ApiCallStatus.Error ||
            this.props.tableDataStatus === ApiCallStatus.Error) {
            return this.setState({
                overallApiCallStatus: ApiCallStatus.Error
            })
        }

        // If none return error, and at least one still loading, overall is loading
        if (this.props.columnMetadataStatus === ApiCallStatus.Loading ||
            this.props.tableDefinitionStatus === ApiCallStatus.Loading ||
            this.props.tableDataStatus === ApiCallStatus.Loading) {
            return this.setState({
                overallApiCallStatus: ApiCallStatus.Loading
            })
        }

        if (this.props.columnMetadataStatus === ApiCallStatus.Completed &&
            this.props.tableDefinitionStatus === ApiCallStatus.Completed &&
            this.props.tableDataStatus === ApiCallStatus.Completed) {
            return this.setState({
                overallApiCallStatus: ApiCallStatus.Completed
            })
        }

        return this.setState({
            overallApiCallStatus: ApiCallStatus.NoData
        })
    }

    columnHeaderFormatter(column: ColumnDescription<any>, colIndex: number, components: {sortElement: JSX.Element; filterElement: JSX.Element; }): any {
        let popoverId: string = `${this.props.tableName}-${column.dataField}-definition`
        logger.info("Entering columnHeaderFormatter")

        return <OverlayTrigger
                delay={{show: 250, hide: 400}}
                placement={"top"}
                overlay={
                    <Popover className={"ms-2"}
                             id={popoverId}>
                        <Popover.Header as={"h3"}>Definition</Popover.Header>
                        <Popover.Body>
                            {this.findColumnMetadata(column.dataField).definition !== "" ? this.findColumnMetadata(column.dataField).definition : column.text}
                        </Popover.Body>
                    </Popover>
                }
            >
                <div style={
                    // If column has custom css class, don't set minWidth.
                    typeof column.classes === "string" && column.classes.length > 0 ?
                            {} :
                            {minWidth: "10rem"}}>
                    <Row>
                        <Col md={12} sm={12}><label htmlFor={popoverId}>{column.text}</label></Col>
                        <Col md={12}>{this.props.enableSorting && <span className={"text-end"}>{components.sortElement}</span>}</Col>
                        <Col md={12}>{components.filterElement}</Col>
                    </Row>
                </div>
            </OverlayTrigger>
    }

    findColumnMetadata(columnName: string): ColumnMetadata {
        for (var columnMetadata of this.props.columnMetadata) {
            if (columnMetadata.name === columnName) {
                return columnMetadata
            }
        }
        return {
            name: "",
            definition: "",
            hidden: true,
        }
    }

    setColumnDescription() {
        let columnsDescriptions: ColumnDescription[] = []
        for (var columnMetadata of this.props.columnMetadata) {
            let columnName: string = columnMetadata.name
            columnsDescriptions.push({
                classes: (cell: any, row: any, rowIndex: number, colIndex: number): string => {
                    return TableColumnFormatter.renderCssClasses(cell, row, rowIndex, colIndex, columnName, this.props.tableName, this.props.columnMetadata.length)
                },
                dataField: columnName,
                text: StringFormatter.snakeCaseToSpaceSeperatedCapitalizedString(columnName),
                hidden: columnMetadata.hidden,
                sort: true,
                sortCaret: ReactBootrapTableSorting.renderSortCadret,
                sortFunc: ReactBootrapTableSorting.tableColumnSort,
                formatter: TableCellFormater.cellFormatter,
                // Display column definition if hover over column name
                headerFormatter: this.columnHeaderFormatter.bind(this),
                filter: textFilter({
                    placeholder: "Filter column",
                    className: TableColumnFormatter.renderCssClassForFilter(this.props.columnMetadata.length),
                }),
            })
        }
        // Inject uuid_enginebio_io column and hide it from UI
        columnsDescriptions.push({
            dataField: KEY_FIELD,
            text: "",
            hidden: true,
            csvExport: false,
        })
        this.setState({
            columnDescriptions: columnsDescriptions
        })
    }

    injectIndexColumn(tableData: any[]): any[] {
        if (this.props.keyField === "") {
            return tableData
        }
        return tableData.map((row) => ({
            ...row,
            KEY_FIELD: uuidv4()
        }))
    }

    componentDidMount() {
        this.setApiOverallStatus()
        this.setColumnDescription()
    }

    componentDidUpdate(prevProps: Readonly<SearchResultTableViewOnlyProps>, prevState: Readonly<SearchResultTableViewOnlyState>, snapshot?: any) {
        if (prevProps.refreshToken !== this.props.refreshToken ||
            prevProps.columnMetadataStatus !== this.props.columnMetadataStatus ||
            prevProps.tableDefinitionStatus !== this.props.tableDefinitionStatus ||
            prevProps.tableDataStatus !== this.props.tableDataStatus ||
            prevProps.tableData !== this.props.tableData) {
            this.setApiOverallStatus()
            this.setColumnDescription()
        }
    }

    render() {
        logger.debug("render - tableData = ", this.props.tableData)
        if (this.state.columnDescriptions.length === 0) {
            return <></>
        }

        // @ts-ignore
        const CustomToggleList = ({columns, toggles, onColumnToggle}) => (
            <div className={"table-responsive p-3 mt-2 mb-2 border border-1 border-dark border-opacity-75"}>
                <p><Badge bg={"primary"}>Show</Badge>/<Badge bg={"secondary"}>Hide</Badge> columns</p>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    {
                        columns
                            .map((column: any) => ({
                                ...column,
                                toggle: toggles[column.dataField]
                            }))
                            .map((column: any) => {
                                if (column.dataField === KEY_FIELD) {
                                    return <></>
                                }
                                return <button
                                    type="button"
                                    key={column.dataField}
                                    className={`btn btn-sm toggle-small-text ${column.toggle ? 'btn-outline-primary' : 'btn-outline-secondary'}`}
                                    data-toggle="button"
                                    aria-pressed={column.toggle ? 'true' : 'false'}
                                    onClick={() => onColumnToggle(column.dataField)}
                                >
                                    {column.text}
                                </button>
                            })
                    }
                </div>
            </div>

        );

        return <>
            {this.props.title !== "" && <h3 className={"text-primary mt-3"}>{this.props.title}</h3>}
            {this.props.smallTitle !== "" && <p className={"mt-3"}>{this.props.smallTitle}</p>}
            <Card className="bg-light mb-3">
                <Card.Header>
                    <Row>
                        <Col md={12}>
                            <p>{this.props.tableDescription}</p>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {this.state.overallApiCallStatus === ApiCallStatus.Loading && <div className={"text-primary"}>Searching ...</div>}
                    {this.state.overallApiCallStatus === ApiCallStatus.Error &&
                        <div className={"text-danger"}>
                            {this.props.columnMetadataErrorMessage && <p>{this.props.columnMetadataErrorMessage}</p>}
                            {this.props.tableDefinitionErrorMessage && <p>{this.props.tableDefinitionErrorMessage}</p>}
                            {this.props.tableDataErrorMessage && <p>{this.props.tableDataErrorMessage}</p>}
                        </div>
                    }
                    {
                        this.state.overallApiCallStatus === ApiCallStatus.Completed &&
                        this.props.tableData.length >= ROW_LIMIT[this.props.tableName] &&
                        <Alert variant={"warning"}>
                            <Alert.Heading>Warning!</Alert.Heading>
                            Too many results. Only display up to {ROW_LIMIT[this.props.tableName]} entries.
                        </Alert>
                    }
                    {(this.state.overallApiCallStatus === ApiCallStatus.Completed) && <ToolkitProvider
                        keyField={this.props.keyField === "" ? KEY_FIELD : this.props.keyField}
                        data={this.injectIndexColumn(this.props.tableData)}
                        columns={this.state.columnDescriptions}
                        exportCSV={{onlyExportFiltered: true, exportAll: false}}
                        columnToggle
                        search
                    >
                        {
                            props => (
                                <div>
                                    <Row>
                                        <Col md={6}>
                                            <Search.SearchBar placeholder={`Filter ${this.props.tableData.length} search results`} {...props.searchProps} />
                                        </Col>
                                        <Col md={6}>
                                            <ExportCSVButton className={"btn-success btn-sm float-end"} {...props.csvProps}><CloudDownload/> Export filtered row</ExportCSVButton>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className={"mt-2 table-responsive"}>
                                            <CustomToggleList {...props.columnToggleProps}/>
                                        </Col>
                                    </Row>
                                    <Row className={"mt-2"}>
                                        <BootstrapTable
                                            hover
                                            bordered={true}
                                            rowClasses = {this.props.rowClasses}
                                            filter={filterFactory()}
                                            wrapperClasses={`table-responsive ${this.props.columnMetadata.length <= 20 ? "" : "table-small-text"}`} // Table with lots of column will automatically have smaller text
                                            noDataIndication={<h6 className={"text-primary"}>No data found!</h6>}
                                            pagination={this.props.displayPagination ? paginationFactory({
                                                sizePerPageList: [10, 15, 20, 50, 100],
                                                hideSizePerPage: false,
                                                showTotal: true
                                            }) : undefined}
                                            bootstrap4={true}
                                            {...props.baseProps}
                                        />
                                    </Row>
                                </div>
                            )
                        }
                    </ToolkitProvider>}
                </Card.Body>
            </Card>
        </>;
    }
}