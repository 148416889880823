function renderSLStatusColor(cellVal: any): string[] {
    cellVal = cellVal.trim().toLowerCase()
    switch (cellVal) {
        case "sl":
            return ["bg-light-green", "text-dark"]
        case "ss":
            return ["bg-pink", "text-dark"]
        case "nsl":
            return ["bg-light", "text-dark"]
        default:
            return ["bg-secondary", "text-light"]
    }
}

const TABLE_COLUMN_CSS_CLASS_FUNC_MAPPING: Record<string, Record<string, (cellVal: any) => string[]>> = {
    "s3_gen_gen_pair_level": {
        "pair_sl_call": renderSLStatusColor
    },
    "s3_gen_gen_plate_level": {
        "plate_sl_call": renderSLStatusColor
    },
    "s3_gen_gen_cell_line_level": {
        "cell_line_sl_call": renderSLStatusColor
    },
    "s3_gen_gen_combo_level": {
        "sl_status": renderSLStatusColor
    }
}
export class TableColumnFormatter {
    static renderCssClasses(cell: any, row: any, rowIndex: number, colIndex: number, columnName?: string, tableName?: string, noOfColumns?: number): string {
        let cssClasses: string[] = []
        if (noOfColumns && noOfColumns > 20) {
            cssClasses.push("table-small-text")
        }

        if (tableName && TABLE_COLUMN_CSS_CLASS_FUNC_MAPPING.hasOwnProperty(tableName) &&
            columnName && TABLE_COLUMN_CSS_CLASS_FUNC_MAPPING[tableName].hasOwnProperty(columnName)) {
            cssClasses.push(...TABLE_COLUMN_CSS_CLASS_FUNC_MAPPING[tableName][columnName](cell))
        }

        return cssClasses.join(" ")
    }

    static renderCssClassForFilter(noOfColumns: number): string {
        if (noOfColumns > 20) {
            return "table-small-text"
        }

        return ""
    }
}