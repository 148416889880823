export const ROW_LIMIT: Record<string, number> = {
    "hgnc": 500,
    "cell_line": 500,
    "plasmid": 500,
    "indel_performance": 500,
    "western_blot": 500,
    "guide": 500,
    "gen_gen_plate": 500,
    "s3_gen_gen_pair_level": 10000,
    "s3_gen_gen_cell_line_level": 10000,
    "s3_gen_gen_plate_level": 10000,
    "s3_gen_gen_combo_level": 10000,
}