import {useTheme, View, Image, withAuthenticator} from "@aws-amplify/ui-react";
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {SystemNotification} from "./components/Alerts/SystemNotification";
import {Alert} from "react-bootstrap";
import {BioToolsUi} from "./components/BioToolsUi";
import {Auth} from "@aws-amplify/auth";
import {Amplify, API, Storage} from "aws-amplify";
import {commonApiConsts} from "./components/CommonApi/Const";
import {genGenPlateApiConst} from "./components/DataCatalog/GenGenPlate/GenGenPlateApiConst";
import {synergyFinderConsts} from "./components/SynergyFinder/Const";
import {gppApiConsts} from "./components/DataCatalog/GuidePlasmidIndel/GppApiConsts";
import {authConfig, awsconfig} from "./Const";
import {sangerQcApiConsts} from "./components/SangerQC/Const";
import '@aws-amplify/ui-react/styles.css';
import enginebio_icon from "./output-onlinepngtools.png";
import {dataHubApiConsts} from "./components/DataCatalog/DataHub/dataHubApiConsts";

Amplify.configure(awsconfig);
Auth.configure(authConfig);
API.configure(
    {
        API: {
            endpoints: [
                {
                    name: sangerQcApiConsts.API_NAME,
                    endpoint: sangerQcApiConsts.API_ENDPOINT,
                },
                {
                    name: gppApiConsts.API_NAME,
                    endpoint: gppApiConsts.API_ENDPOINT,
                },
                {
                    name: commonApiConsts.API_NAME,
                    endpoint: commonApiConsts.API_ENDPOINT
                },
                {
                    name: synergyFinderConsts.API_NAME,
                    endpoint: synergyFinderConsts.API_ENDPOINT
                },
                {
                    name: genGenPlateApiConst.API_NAME,
                    endpoint: genGenPlateApiConst.API_ENDPOINT
                },
                {
                    name: dataHubApiConsts.API_NAME,
                    endpoint: dataHubApiConsts.API_ENDPOINT,
                }
            ],
        },
    }
);
Storage.configure(awsconfig);


// @ts-ignore
function App({signOut, user}: any) {
    return <BioToolsUi signOut={signOut} user={user}/>
}

// FIXME for another proj - Sai motivosity for echo.enginebio.io log in less than 6 months
export default withAuthenticator(App, {
    formFields: {
        signIn: {
            username: {
                labelHidden: true,
                placeholder: 'anybody@enginebio.com',
            },
        },
        signUp: {
            username: {
                labelHidden: true,
                placeholder: "Your enginebio.com internal email",
                isRequired: true,
            },
        }
    },
    components: {
        SignIn: {
            Header() {
                const {tokens} = useTheme()
                return <SystemNotification padding={tokens.space.small}  style={{marginBottom: "-2rem"}} />
            }
        },
        Header() {
            const {tokens} = useTheme();
            return (
                <View textAlign="center" padding={tokens.space.small} size>
                    <Image
                        alt="Engine logo"
                        src={enginebio_icon}
                    />
                </View>
            );
        },
        SetupTOTP: {
            Header() {
                const {tokens} = useTheme();
                return <View padding={tokens.space.small} size>
                    <Alert variant={"info"}>
                        <p>To protect Engine's data from malicious attackers, you must set-up MFA for your account.
                            Please install MFA app on your phone (we recommend Google Authenticator for iOS & Android),
                            or use existing MFA feature of your password manager, to scan QR code below.</p>
                    </Alert>
                </View>
            }
        }
    }
})
