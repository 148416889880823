import React from "react";
import {
    CaretDownFill,
    CaretUpFill,
} from "react-bootstrap-icons";
import {ColumnDescription} from "react-bootstrap-table-next";

export class ReactBootrapTableSorting {
    static tableColumnSort(a: string, b: string, order: string, dataField: any, rowA: any, rowB: any): number | any {
        // Decide to skip date comparison, too complicated, and little value
        // Check if it is number
        if (order === "asc") {
            // If both a and b are number
            if (b === "") {
                return -1
            }

            if (a === "") {
                return  1
            }

            if (ReactBootrapTableSorting.isNumber(a) && ReactBootrapTableSorting.isNumber(b)) {
                return parseFloat(b) - parseFloat(a)
            }

            return b.toString().localeCompare(a.toString())
        }

        if (a === "") {
            return -1
        }

        if (b === "") {
            return 1
        }

        if (ReactBootrapTableSorting.isNumber(a) && ReactBootrapTableSorting.isNumber(b)) {
            return parseFloat(a) - parseFloat(b)
        }
        return a.toString().localeCompare(b.toString())
    }

    static isNumber(str: any): boolean {
        if (typeof str === "number") {
            return true;
        }

        if (typeof str !== 'string') {
            return false;
        }

        if (str.trim() === '') {
            return false;
        }

        return !Number.isNaN(Number(str));
    }

    static renderSortCadret(order: "desc" | "asc" | undefined, column: ColumnDescription<any, any>): JSX.Element {
        switch (order) {
        case "desc":
            return <CaretDownFill color={"royalblue"} />
        case "asc":
            return <CaretUpFill color={"royalblue"} />
        case undefined:
                return <CaretDownFill />
        default:
            return <></>
        }
    }
}
