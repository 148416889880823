import {API, Logger} from "aws-amplify";
import React from "react";
import {ApiCallStatus} from "../Common/Enum";
import {SearchResultTableViewOnly} from "../Common/SearchResultTableViewOnly";
import {dataHubApiConsts} from "./dataHubApiConsts";
import {ColumnMetadata} from "../Common/ColumnMetadata";

const logger: Logger = new Logger("DataHubSearchResultTable.tsx")

interface DataHubSearchResultTableState {
    tableData: Object[]
    tableDataStatus: ApiCallStatus
    tableDataError: string

    tableDefinition: string
    tableDefinitionStatus: ApiCallStatus
    tableDefinitionError: string

    columnMetadata: ColumnMetadata[]
    columnMetadataStatus: ApiCallStatus
    columnMetadataError: string
}

interface DataHubSearchResultTableProps {
    refreshToken: string
    tableName: string
    tableTitle: string
    keywords: string
}

export class DataHubSearchResultTable extends React.Component<DataHubSearchResultTableProps, DataHubSearchResultTableState> {
    constructor(props: DataHubSearchResultTableProps) {
        super(props);
        this.state = {
            tableDefinition: "",
            tableDefinitionStatus: ApiCallStatus.NoData,
            tableDefinitionError: "",

            columnMetadata: [],
            columnMetadataStatus: ApiCallStatus.NoData,
            columnMetadataError: "",

            tableData: [{"dummy_column": "dummy_data"}],
            tableDataStatus: ApiCallStatus.NoData,
            tableDataError: "",
        }
    }

    setTableDefinition() {
        // Query data catalog to get table description
        logger.info(`Query data catalog to get table description of ${this.props.tableName}`)
        this.setState(
            {
                tableDefinition: "",
                tableDefinitionStatus: ApiCallStatus.Loading,
                tableDefinitionError: ""
            },
            () => {
                // Will not triggered by user, thus doesn't require UsageTracking
                API.get(
                    dataHubApiConsts.API_NAME,
                    dataHubApiConsts.API_PATH.TABLE_DEFINITION,
                    {
                        queryStringParameters: {
                            tableName: this.props.tableName,
                        },
                    }).then(response => {
                    logger.debug("Response from API")
                    logger.debug(response)
                    this.setState({
                        tableDefinition: response["data"],
                        tableDefinitionStatus: ApiCallStatus.Completed,
                        tableDefinitionError: "",
                    })
                }).catch(err => {
                    logger.error(err)
                    this.setState({
                        tableDefinition: "",
                        tableDefinitionStatus: ApiCallStatus.Error,
                        tableDefinitionError: err+""
                    })
                }).finally(() => {

                })
            }
        )
    }

    setColumnDefinition(callback: () => void) {
        logger.info(`Query data catalog to get column definition of ${this.props.tableName}`)
        this.setState(
            {
                columnMetadata: [],
                columnMetadataStatus: ApiCallStatus.Loading,
                columnMetadataError: ""
            },
            () => {
                // FIXME - get column order first
                let columnOrder: string[] = []
                API.get(
                    dataHubApiConsts.API_NAME,
                    dataHubApiConsts.API_PATH.COLUM_ORDER,
                    {
                        queryStringParameters: {
                            tableName: this.props.tableName
                        },
                    })
                    .then(response => {
                        logger.info("Response from column-order API")
                        logger.info(response)
                        columnOrder = response["data"]
                    })
                    .catch(err => {
                        logger.error(err)
                        this.setState({
                            columnMetadata: [],
                            columnMetadataStatus: ApiCallStatus.Error,
                            columnMetadataError: err+""
                        })
                    }).finally(() => {
                        // Will not triggered by user, thus doesn't require UsageTracking
                        if (columnOrder.length === 0) {
                            return
                        }
                        API.get(
                            dataHubApiConsts.API_NAME,
                            dataHubApiConsts.API_PATH.COLUMN_METADATA,
                            {
                                queryStringParameters: {
                                    tableName: this.props.tableName
                                },
                            })
                            .then(response => {
                                logger.info("Response from API", dataHubApiConsts.API_PATH.COLUMN_METADATA)
                                logger.info(response)

                                // Reorder column metadata according to output of column-order API
                                let columnMetadataArr: ColumnMetadata[] = []
                                for (const columnName of columnOrder) {
                                    for (const columnMetadata of response["data"]) {
                                        if (columnMetadata.name === columnName) {
                                            columnMetadataArr.push(columnMetadata)
                                        }
                                    }
                                }
                                this.setState({
                                    columnMetadata: columnMetadataArr,
                                    columnMetadataStatus: ApiCallStatus.Completed,
                                    columnMetadataError: "",
                                }, callback)
                            })
                            .catch(err => {
                                logger.error(err)
                                this.setState({
                                    columnMetadata: [],
                                    columnMetadataStatus: ApiCallStatus.Error,
                                    columnMetadataError: err+""
                                })
                            }).finally(() => {

                        })
                    })
            }
        )
    }

    searchTableData() {
        if (Object.keys(this.state.columnMetadata).length === 0) {
            return this.setState({
                tableDataStatus: ApiCallStatus.Error,
                tableData: [],
                tableDataError: "There is no column metadata"
            })
        }

        if (this.props.keywords.trim() === "") {
            return this.setState({
                tableDataStatus: ApiCallStatus.Error,
                tableData: [],
                tableDataError: "Keywords must be entered"
            })
        }

        // TODO - add UsageTracking

        logger.info(`Query data catalog to get table data of ${this.props.tableName} with keyword ${this.props.keywords}`)
        this.setState({
            tableDataStatus: ApiCallStatus.Loading,
            tableData: [],
            tableDataError: ""
        }, () => {
            // No UsageTracking here, because we recorded it at componentDidUpdate

            API.get(
                dataHubApiConsts.API_NAME,
                dataHubApiConsts.API_PATH.TABLE_DATA,
                {
                    queryStringParameters: {
                        keywordsCommaSeperated: this.props.keywords,
                        tableName: this.props.tableName,
                        columnCommaSeperated: this.state.columnMetadata.map((column) => column.name).join(","),
                        displayColumnCommaSeperated: this.state.columnMetadata.map((column) => column.name).join(","),
                    },
                })
                .then(response => {
                    logger.debug("Response from API")
                    logger.debug(response)
                    this.setState({
                        tableData: response["data"],
                        tableDataStatus: ApiCallStatus.Completed,
                        tableDataError: "",
                    })
                }).catch(err => {
                    logger.error(err)
                    if (err.response.status === 504) {
                        // Unlike other error code, 504 actually didn't indicate any problem at backend, it's just DB need to warm-up
                        this.setState({
                            tableDataStatus: ApiCallStatus.Error,
                            tableData: [],
                            tableDataError: "Database need to warm-up, please retry the search."
                        })
                    } else {
                        this.setState({
                            tableDataStatus: ApiCallStatus.Error,
                            tableData: [],
                            tableDataError: err+""
                        })
                    }
                }).finally(() => {

                })
        })
    }

    filterTableDataForOneColumn(keywordCommaSeperated: string, columnName: string, callbackFunc: () => void): void {
        if (Object.keys(this.state.columnMetadata).length === 0) {
            return this.setState({
                tableDataStatus: ApiCallStatus.Error,
                tableData: [],
                tableDataError: "There is no column metadata"
            })
        }

        if (keywordCommaSeperated.trim() === "") {
            return this.setState({
                tableDataStatus: ApiCallStatus.Error,
                tableData: [],
                tableDataError: "Keywords must be entered"
            })
        }

        // TODO - add UsageTracking

        logger.info(`Query data catalog to get table data of ${this.props.tableName} with keyword ${this.props.keywords}`)
        this.setState({
            tableDataStatus: ApiCallStatus.Loading,
            tableData: [],
            tableDataError: ""
        }, () => {
            // No UsageTracking here, because we recorded it at componentDidUpdate
            API.get(
                dataHubApiConsts.API_NAME,
                dataHubApiConsts.API_PATH.TABLE_DATA,
                {
                    queryStringParameters: {
                        keywordsCommaSeperated: keywordCommaSeperated,
                        tableName: this.props.tableName,
                        columnCommaSeperated: columnName,
                        displayColumnCommaSeperated: Object.keys(this.state.columnMetadata).join(","),
                    },
                })
                .then(response => {
                    logger.debug("Response from API")
                    logger.debug(response)
                    this.setState({
                        tableData: response["data"],
                        tableDataStatus: ApiCallStatus.Completed,
                        tableDataError: "",
                    })
                }).catch(err => {
                    logger.error(err)
                    this.setState({
                        tableDataStatus: ApiCallStatus.Error,
                        tableData: [],
                        tableDataError: err+""
                    })
                }).finally(() => {
                    callbackFunc()
                })
        })
    }

    componentDidMount() {
        this.setTableDefinition()
        this.setColumnDefinition(this.searchTableData)
    }

    componentDidUpdate(prevProps: Readonly<DataHubSearchResultTableProps>, prevState: Readonly<DataHubSearchResultTableState>, snapshot?: any) {
        if (prevProps.refreshToken !== this.props.refreshToken) {
            // UsageTracking already place at ../Search.tsx
            this.setTableDefinition()
            this.setColumnDefinition(this.searchTableData)
        }
    }

    render() {
        if (this.state.columnMetadataStatus === ApiCallStatus.NoData
            || this.state.tableDefinitionStatus === ApiCallStatus.NoData
            || this.state.tableDataStatus === ApiCallStatus.NoData) {
            return <></>
        }

        return <SearchResultTableViewOnly
            refreshToken={this.props.refreshToken}
            title={this.props.tableTitle}
            legends={<></>}
            rowClasses={() => ""}
            dataAdmin={""}
            columnsFormats={{}}
            tableName={this.props.tableName}

            tableDescription={this.state.tableDefinition}
            tableDefinitionStatus={this.state.tableDefinitionStatus}
            tableDefinitionErrorMessage={this.state.tableDefinitionError}

            columnMetadata={this.state.columnMetadata}
            columnMetadataStatus={this.state.columnMetadataStatus}
            columnMetadataErrorMessage={this.state.columnMetadataError}

            tableData={this.state.tableData}
            tableDataStatus={this.state.tableDataStatus}
            tableDataErrorMessage={this.state.tableDataError}

            enableColumnFilter={true}
            filterTableDataForOneColumn={this.filterTableDataForOneColumn.bind(this)}

            enableSorting={true}
            displayPagination={true}
        />;
    }
}